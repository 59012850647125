export default {
  'en': {
    '': '',
  },
  'fr': {
    'Fax': 'Télécopieur',
    'Email': 'Courriel',
    'Search': 'Recherche',
    'Agency': 'Bureau',
    'Mobile': 'Mobile',
    'For Sale': 'À vendre',
    'For Lease': 'À louer',
    '/Sq.Ft.': '/pi²',
    'Sq Ft': 'pi²',
    '/Month': '/Mois',
    'MONTH': 'Mois',
    'Sq.Ft': 'pi²',
    'Sq.M': 'm²',
    'RETAIL': 'DÉTAIL',
    'LAND': 'TERRAIN',
    'BUSINESS': 'ENTREPRISE',
    'AGRICULTURAL': 'AGRICOLE',
    'OFFICE': 'BUREAU',
    'INDUSTRIAL': 'INDUSTRIEL',
    'OTHER': 'AUTRE',
    'MULTI-FAMILY': 'MULTIFAMILIAL',
    'INSTITUTIONAL': 'INSTITUTIONNEL',
    'Details': 'Des détails',
    'results': 'résultats',
    'DESCRIPTION': 'LA DESCRIPTION',
    'ADDRESS': 'ADRESSE',
    'FEATURES': 'CARACTÉRISTIQUES',
    'Description': 'La description',
    'Address': 'Adresse',
    'Features': 'Caractéristiques',
    'City': 'Ville',
    'Province': 'Province',
    'Postal Code': 'Code postal',
    'View Listings': 'Voir les annonces',
    'Name': 'Nom',
    'Phone': 'Téléphone',
    'Hello, I am interested in': 'Bonjour, je suis intéressé par',
    'Select': 'Sélectionner',
    'By submitting this form, I agree to the': 'L\'envoi de ce formulaire atteste de mon consentement aux',
    'Terms of Use': 'Conditions d\'utilisation',
    'Send Message': 'Envoyer le message',
    'I\'m a buyer': 'Je suis un acheteur',
    'I\'m a tennant': 'Je suis locataire',
    'I\'m an agent': 'Je suis un agent',
    'Other': 'Autre',
    'Open on Google Maps': 'Ouvrir sur Google Maps',
    'Overview': 'Aperçu',
    'Share': 'Partager',
    'Print': 'Imprimer',
    'Picture': 'Image',
    'Map': 'Carte',
    'Open': 'Ouvrir',
    'Call': 'Appel',
    'Send Email': 'Envoyer courriel',
    'Specialties': 'Spécialités',
    'Service Areas': 'Zones desservies',
    'About': 'À propos de',
    'Languages': 'Langues',
    'Spoken Languages': 'Langues parlées',
    'Listings': 'Propriétés offertes',
    'Sort by': 'Trier par',
    'at': 'à',
    'Default Order': 'Commande par défaut',
    'Price - Low to High': 'Prix-de bas en haut',
    'Price - High to Low': 'Prix-haut en bas',
    'Date - Old to New': 'Ancien à nouveau',
    'Date - New to Old': 'Nouveau à l\'\'ancien',
    'of': 'de',

    '"First Name Last Name"': '"Nom, Prénom" ',
    '"City", "Postal Code"': '"Ville", "Code Postal"',
    '"City", "Postal Code", "Neighbourhood", "MLS#"': '"Ville", "Code Postal", "Zone", "MLS#"',
    '"Specialty"': '"Spécialité"',
    'Specialty': 'Spécialité',

    'Filter options ...': 'Options de filtrage ...',

    'Name A-Z': 'Nom de A à Z',
    'Name Z-A': 'Nom de Z à A',
    'Contact': 'Contactez',
    'View': 'Voir',

    'Select All': 'Tout sélectionner',
    'Deselect All': 'Tout déselectionner',
    'Statuses': 'Statuts',
    'No results matched': 'Aucun résultat correspondant',
    'Types': 'Les types',
    'Agricultural': 'Agricole',
    'Business': 'Entreprise',
    'Industrial': 'Industriel',
    'Institutional': 'Institutionnel',
    'Land': 'Terrain',
    'Multi Family': 'Multifamilial',
    'Retail': 'Détail',

    'Min. Sq ft': 'Superficie min. en pieds carrés',
    'Max. Sq ft': 'Superficie max. en pieds carrés',
    'Price Range': 'Échelle des prix',
    'From': 'de',
    'To': 'à',
    'Type': 'Type de propriétés',
    'Property': 'Propriété',
    'Status': 'Statut',
    'Cities': 'Villes',
    'Listing': 'Référencement',
    'Tel': 'Téléphone',
    'Website': 'Site web',
    'Agents': 'Courtiers',
    'Contact this office': 'Contactez ce bureau',
    '"Office Name"': '"Nom du bureau"',
    'Office': 'Bureau',
    'Sorry, no Properties found. Please refine your search.': 'Désolé, aucun propriété n\'a été trouvé.Veuillez affiner votre recherché.',
    'Sorry, no Agents found. Please refine your search.': 'Désolé, aucun courtier n\'a été trouvé.Veuillez affiner votre recherché.',
    'Sorry, no Offices found. Please refine your search.': 'Désolé, aucun bureau n\'a été trouvé.Veuillez affiner votre recherché.',
    'Please zoom out on the map to include more results or change your search criteria.': 'Veuillez effectuer un zoom arrière sur la carte pour inclure plus de résultats ou modifier vos critères de recherche.',
    'Contact agent': 'Contacter le courtier',
    'Unlimited': 'Illimité',
    'https://www.royallepage.ca/en/realestate/legal-notice/': 'https://www.royallepage.ca/fr/immobilier/avis-juridique/',
    'Awards': 'Distinctions',
    'https://www-d.royallepage.ca/wp-content/2019/02/award_logo_comm-national10_eng.jpg': 'https://www-d.royallepage.ca/wp-content/2019/02/award_logo_comm-national10_fre.jpg',
    'Advanced Search ...': 'Plus de critères ...',
    'Inquiry Type': 'Type de requête',
    'Information': 'Information',
    'Purchase': 'Achat',
    'Sell': 'Vente',
    'Lease': 'Louer',
    'Evaluation': 'Évaluation',
    'I\'m a real estate agent': 'Je suis courtier immobilier',
    'I\'m a property owner': 'Je suis propriétaire',
    'Other Information': 'les autres informations',
    'Your Name': 'Votre nom',
    'Email Address': 'Adresse courriel',
    'Max Price': 'Prix Max',
    'Min Size Sq Ft': 'Superficie min. en pieds carrés',
    'Submit': 'Soumettre',
    'Your email has been sent successfully!': 'Votre courriel a bel et bien été envoyé!',
    'Error occured while submitting your email!': 'Une erreur est survenue avec votre courriel!',
    'Please make a selection.': 'Veuillez effectuer une sélection.',
    'Please provide your name.': 'Veuillez inscrire votre nom.',
    'Please provide a valid email address.': 'Veuillez inscrire une adresse courriel valide.',
    'Request Info': 'Demande de renseignements',
    'Please provide a valid phone number.': 'Veuillez fournir un numéro de téléphone valide',

    'Preferred method of contact': 'Moyen de communication à privilégier',
    'Pleaes provide some detail information.': 'Veuillez fournir des informations détaillées.',
    'You must agree before submitting.': 'Vous devez donner votre consentement avant de soumettre.',
    'Hi': 'Bonjour',
    'I saw your profile on the Royal LePage Commercial website, and I have a few questions. Please contact me.': 'J\'ai vu votre profil sur le site Web de Royal LePage Commercial, et j\'ai quelques questions à vous poser. Veuillez communiquer avec moi.',
    'Phone Number': 'Numéro de téléphone',
    'Message': 'Message',
    'General Inquiry at rlpCommercial home page': 'Demande générale sur la page d\'accueil rlpCommercial',
    'Inquiry regarding - ': 'Demande concernant - ',
    'Inquiry posted at rlpCommercial.com': 'Demande publiée sur rlpCommercial.com',
    'A new message has been received': 'Un nouveau message a été reçu',
    'Other Infomation': 'Les autres Informations',
    'Please provide your Information': 'Veuillez fournir vos informations',
    'Max price': 'Prix max',
    'Estate agent': 'Agent immobilier',
    'Property owner': 'Propriétaire',
    'Property type': 'Type de propriétés',
    'https://www.royallepage.ca/en/realestate/privacy-policy/': 'https://www.royallepage.ca/fr/immobilier/politique-de-confidentialite/',
    'Royal LePage Commercial does not share your contact information outside of our organization. Read our': 'Royal LePage Commercial ne communique aucune de vos coordonnées à quiconque à l\'extérieur de son organisation. Lire notre',
    'Privacy Policy.': 'politique de confidentialité.',
    'I would like more information on this property, please contact me.': 'J\'aimerais obtenir plus de renseignements au sujet de cette propriété. Veuillez communiquer avec moi.',
    'I\'m looking for more information on a property.Please contact me.': 'J\'aimerais obtenir de plus amples renseignements au sujet d\'une propriété. Veuillez communiquer avec moi.',
    'Alberta': 'Alberta',
    'British Columbia': 'Colombie-Britannique',
    'Manitoba': 'Manitoba',
    'New Brunswick': 'Nouveau-Brunswick',
    'Newfoundland and Labrador': 'Terre-Neuve-et-Labrador',
    'Northwest Territories': 'Territoires du Nord-Ouest',
    'Nova Scotia': 'Nouvelle-Écosse',
    'Nunavut': 'Nunavut',
    'Ontario': 'Ontario',
    'Prince Edward Island': 'Île-du-Prince-Édouard',
    'Quebec': 'Québec',
    'Saskatchewan': 'Saskatchewan',
    'Yukon': 'Yukon',
    'Your Email': 'Votre courriel',
    'Yes': 'Oui',
    'No': 'Non',
    'Agent Email': 'Courtier courriel',
    'Are you under a signed contract with an agent?': 'Êtes-vous déjà sous contrat avec un autre courtier immobilier?',
    'The commercial listing below has been forwarded to you from the following contact': 'L\'inscription commerciale ci- dessous vous a été transférée par le contact ci- dessous',
    'Commercial Listing From Royal LePage Commercial': 'Inscription commerciale de Royal LePage Commercial',
    'This listing content provided by REALTOR.ca has been licensed by REALTOR® members of The Canadian Real Estate Association.': 'Le contenu des inscriptions fournies par REALTOR.ca a été autorisé par les membres de l\'Association canadienne de l\'immeuble.',
    'Courtesy of': 'Gracieuseté de',
    'Please provide a valid agent email address.': 'Veuillez inscrire une adresse courriel valide du courtier immobilier',
    'Please contact your agent directly about this listing.': 'Veuillez contacter votre courtier directement à propos de cette inscription.',
    'Close': 'Fermer',
    'Please provide city name.': 'Veuillez indiquer le nom de la ville.',
    'No result, here are nearby agents.': 'Aucun résultat, voici des agents à proximité.',
    'Too big geographical area. Please refine the search.': 'Zone géographique trop grande. Veuillez affiner la recherche.',
    '/Sq.m.': '/m²',
    '/Acre': '/Acre',
    'Hey rlp Agent, please contact the listing agent.': 'Salut l\'agent rlp, veuillez contacter l\'agent inscripteur.',
    'See more local listings': 'Voir plus d\'inscriptions',
    'Hotels/Motels': 'Hôtels/Motels',
    'Investment': 'Bien d\'investissement',
    'Lot Frontage': 'Largeur du terrain en façade',
    'Lot Depth': 'Profondeur du terrain',
    'Lot Size': 'Dimensions du terrain',
    'Floor Space': 'Surface utile',
    'Bathrooms': 'Salle(s) de bains',
    'Annual Tax Amount': 'Taxes annuelles totales',
    'No. of Parking Spaces': 'Nbre d\'espaces de stationnement',
    'Zoning Type': 'Zonage',
    'Neighbourhood': 'Détails sur le quartier',
    'Size': 'Dimensions',
    'Water': 'Eau',
    'The listing you are looking for no longer exists.': 'L\'inscription que vous cherchez n\'existe plus.',
    'Similar Listings': 'Inscriptions semblables',
    'Exclusive Listings': 'Inscriptions exclusives',
    'https://www.realtor.ca/images/en-ca/powered_by_realtor.svg': 'https://www.realtor.ca/images/fr-ca/powered_by_realtor.svg',
    'Sale Price': 'À vendre',
    'Lease Rate ($/SF/Mo/YR)':'À louer ($/pi²/mois/an)',
    'Min':'Min',
    'Max': 'Max',
    '': '',
    'Multimedia':'Multimédia',
    'Virtual Tours':'Visites virtuelles',
    'Audio':'Audio',
    'Video':'Vidéo',
    'Slideshows':'Diaporamas',
    'Brochures':'Brochures',
    'Web Commercials': 'Web Commercials',
    'Additional Photos': 'Photos supplémentaires',
    'Floorplan': 'Plan d\'étage',
  }
};
